import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants';
import toast, { Toaster } from 'react-hot-toast';

const Contact = () => {

  
  const notify = () => toast('You have successfully sent me an email');

const contact = useRef();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3opjpuw', 
    'template_2mmvsgd', 
    form.current, 
    'fJiDRuOGqJIgF6vpp')
      .then((result) => {
          
          console.log(result.text);
          form.current.name.value = '';
          form.current.email.value = '';
          form.current.message.value = '';
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
  <section className='py-16 lg:section' id='contact'>
    <div ref={contact} className='container mx-auto'>
      <div className='flex flex-col lg:flex-row'>
        {/* text */}
        <motion.div 
        variants={fadeIn('right', 0.3)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        className='flex-1 flex justify-start items-center'>
          <div>
            <h4 className='text-xl uppercase text-accent font-medium mb-2 
            tracking-wide'>
              Get in touch
            </h4>
            <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>
              Let's work <br /> together!
            </h2>
          </div>
        </motion.div>
        {/* form */}
        <motion.form 
        variants={fadeIn('left', 0.5)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        ref={form}
        onSubmit={sendEmail}
        className='flex-1 border rounded-2xl flex flex-col gap-y-6
        pb-24 p-6 items-start'>
          <input className='bg-transparent border-b py-3 outline-none w-full
          placeholder:text-white focus:border-accent transition-all' 
          type='text'
          name='name'
          placeholder='Your name'
          />
          <input className='bg-transparent border-b py-3 outline-none w-full
          placeholder:text-white focus:border-accent transition-all' 
          type='text'
          name='email'
          placeholder='Your email'
          />
          <textarea className='bg-transparent border-b py-12 outline-none 
          w-full placeholder:text-white focus:border-accent transition-all 
          resize-none mb-12' 
          name='message'
          placeholder='Your message'></textarea>
          <button type='submit' onClick={notify} className='btn btn-lg'>Send message</button>
        </motion.form>
        <Toaster  />

      </div>
    </div>
  </section>
  )
};

export default Contact;
