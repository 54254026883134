import React from 'react';
//icons
import { BsArrowUpRight } from 'react-icons/bs';
//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants';

//services data
const services = [
  {
    name: 'UI/UX Design',
    description:
      ' UX and UI leader and designer with over 24 years of experience crafting websites, apps, and digital products for some of the worlds most notable brands. I enjoy combining user research, design, and technology into intuitive digital experiences that make a difference in peoples lives.',
    link: 'Learn More',
  },
  {
    name: 'Development',
    description:
      'An accomplished web developer with special expertise in developing web applications. He adapts to the circumstances of the project and picks up the domain-specific language to be able to communicate effectively with clients.',
    link: 'Learn More',
  },
  {
    name: 'Mobile Development',
    description:
      'I can build awesome apps across mobile, desktop, and web, with the React you know and love',
    link: 'Learn More',
  },
  
];

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* text & images */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 lg:bg-services lg:bg-bottom bg-no-repeat
          mix-blend-lighten mb-12 lg:mb-0">
            <h2 className="h2 text-accent mb-6">What I Do.</h2>
            <h3 className="h3 max-w-[455px] mb-16">
              I'm a Freelance Web Developer with over 24 years of experience.
            </h3>
            <button className="btn btn-sm">See my work</button>
          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1">
            {/* service list */}
            <div>
              {services.map((service, index) => {
                //destructure service
                const { name, description, link } = service;
                return (
                  <div
                    className="border-b border-white/20 h-[146px] mb-[38px] flex"
                    key={index}>
                    <div className="max-w-[476px]">
                      <h4
                        className="text-[20px] tracking-wider font-primary
                    font-semibold mb-6">
                        {name}
                      </h4>
                      <p className="font-secondary leading-tight">
                        {description}
                      </p>
                    </div>
                    <div className="flex flex-col flex-1 items-end">
                      <a href="#" className="btn w-9 h-9 mb-[42px] flex justify-center
                      items-center">
                        <BsArrowUpRight />
                      </a>
                      <a href="#" className="text-gradient text-sm">
                        {link}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
