import React from 'react';
// images
import Image from '../assets/image2vector.svg';

//icons
import { FaGithub, FaYoutube, FaDribbble } from 'react-icons/fa';

//type animation
import { TypeAnimation } from 'react-type-animation';

//motion
import { motion } from 'framer-motion';

import { fadeIn } from '../variants';

const Banner = () => {
  const handleClickScroll = () => {
    const element = document.getElementById('contact');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth', spy: true });
    }
  };
  const showPortfolio = () => {
    const element = document.getElementById('portfolio');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth', spy: true });
    }
  };
  return (
    <section
      className="min-h-[85vh] lg:min-h-[75vh] flex items-center"
      id="home">
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-cents lg:gap-x-12">
          {/* text */}
          <div className="flex-1 text-cent font-secondary lg:text-left">
            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[55px] font-bold leading-[0.8] lg:text-[110px]">
              Manny <span>Zamorano</span>
            </motion.h1>
            <motion.div
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-6 text-[36px] lg:text-[60px] font-secondary
          font-semibold uppercase leading-[1]">
              <span className="text-white mr-4"> I am a</span>
              <TypeAnimation
                sequence={[
                  'Designer',
                  2000,
                  'Developer',
                  2000,
                  'DevOps',
                  2000,
                  'Systems',
                  2000,
                ]}
                speed={50}
                className="text-accent"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn('up', 0.5)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-8 max-x-lg mx-auto lg:mx-0">
              With my strong technical skills, extensive experience, and passion for web development, I am confident in my ability to contribute to your team and help drive the success of your projects.
            </motion.p>
            <motion.div
              variants={fadeIn('up', 0.6)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0">
              <button onClick={handleClickScroll} className="btn btn-lg">Contact me</button>
              <a href="#" className="text-gradient btn-link">
                My Portfolio
              </a>
            </motion.div>
            {/* social buttons*/}
            <motion.div 
             variants={fadeIn('up', 0.7)} 
             initial="hidden" 
             whileInView={'show'} 
             viewport={{once: false, amount: 0.7}}
            className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0">
              <a href="https://www.youtube.com/channel/UC_lCw1wB7pp9MhstpjTP6Rw">
                <FaYoutube />
              </a>
              <a href="https://github.com/ManuelZamorano">
                <FaGithub />
              </a>
              <a href="https://dribbble.com/mannozam">
                <FaDribbble />
              </a>
            </motion.div>
          </div>
          {/* image */}
          <motion.div 
           variants={fadeIn('down', 0.5)} 
           initial="hidden" 
           whileInView={'show'} 
           className="hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]">
            <img src={Image} alt="" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
