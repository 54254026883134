import React, { useRef } from 'react';
//images
import Logo from '../assets/logo.png';


const Header = () => {
  const handleClickScroll = () => {
    const element = document.getElementById('contact');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth', spy: true });
    }
  };
  return (
  <header className='py-8'>
    <div className='container mx-auto'>
      <div className='flex justify-between items-center'>
        {/* logo */}
        <a href='#'>
          <img src={Logo} alt='MannyZ'/>
        </a>
        {/* button */}
        <button onClick={handleClickScroll} className='btn btn-sm'>Work with me</button>

      </div>
    </div>
  </header>
  );
};

export default Header;
